import React from "react";
import BackgroundImage from "gatsby-background-image";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import "../assets/sass/about.scss";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../components/Header";
import Loadable from "@loadable/component";
import BookAppointment from "../components/BookAppointment";

const Footer = Loadable(() => import("../components/Footer/index.js"));

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(isFrontPage: { eq: true }) {
          id
          layout {
            aboutsubtitle
            abouttitle
            ctalabel
            aboutherocontent
            aboutcontent
            aboutcontent2
            aboutcontent3
            aboutfooternote
            aboutpath
            aboutheroimage {
              title
              caption
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    originalImg
                    originalName
                    srcSet
                    src
                    sizes
                    presentationWidth
                    presentationHeight
                    aspectRatio
                  }
                }
              }
            }
            aboutcontentimage1 {
              title
              caption
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    originalImg
                    originalName
                    srcSet
                    src
                    sizes
                    presentationWidth
                    presentationHeight
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const {
    aboutsubtitle,
    aboutfooternote,
    abouttitle,
    aboutheroimage,
    aboutcontentimage1,
    aboutherocontent,
    ctalabel,
    aboutcontent,
    aboutcontent2,
    aboutcontent3,
    aboutpath,
  } = data.wpPage.layout;

  let content2 = aboutcontent2.split("\n");
  content2 = [
    content2.slice(0, Math.round(content2.length / 2)).join(""),
    content2.slice(Math.round(content2.length / 2), content2.length).join(""),
  ];
  return (
    <Layout>
      <SEO image={aboutheroimage.localFile.childImageSharp.fluid} />
      <Header frontPage={true} selected="sobre" />
      <header className={`aboutHeader`}>
        <BackgroundImage
          style={{
            backgroundPosition: "center 60%",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundOrigin: "content-box",
          }}
          className={"bgImageFixed aboutContainer"}
          fluid={aboutheroimage.localFile.childImageSharp.fluid}
        >
          <div className="gradient"></div>
        </BackgroundImage>
      </header>
      <section className="aboutSection pt-5 pb-0 p-0 pr-0">
        <div
          className={`container-fluid d-md-flex w-100 intro align-items-center`}
        >
          <div className="row w-100 no-gutters">
            <div className="col-md-1 col-sm-hidden"></div>
            <div className="col-sm-12 col-md-10 align-self-center text-center">
              <Zoom cascade>
                {aboutheroimage.caption && (
                  <span
                    className="subtitle-font pb-2 destak"
                    dangerouslySetInnerHTML={{
                      __html: aboutheroimage.caption,
                    }}
                  ></span>
                )}
                {aboutheroimage.title && (
                  <h1
                    className="title"
                    dangerouslySetInnerHTML={{ __html: aboutheroimage.title }}
                  ></h1>
                )}
              </Zoom>
              <Fade>
                <p className="mb-5">{aboutherocontent}</p>
              </Fade>
            </div>
            <div className="col-md-1 col-sm-hidden text-center"></div>
          </div>
        </div>

        <div className="container-fluid p-0">
          <div className="row justify-content-md-center no-gutters ">
            <div className="col-md-auto pb-5 pr-5 pl-5 col-sm-12 text-block ">
              <Fade>
                <span dangerouslySetInnerHTML={{ __html: aboutcontent }} />
              </Fade>
            </div>
          </div>

          <div className="row">
            <div className="col align-self-center text-center pl-5 pr-5 pb-5 mb-3">
              <Fade>
                <h1 className="sentence">{abouttitle}</h1>
              </Fade>
            </div>
          </div>

          <Fade>
            <BackgroundImage
              style={{
                backgroundPosition: "center",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
                backgroundOrigin: "content-box",
              }}
              className={"row justify-content-md-center no-gutters primary-bg"}
              fluid={aboutcontentimage1.localFile.childImageSharp.fluid}
            >
              <div className="col-md-6 bgAbout col-sm-12 text-block p-5">
                <span dangerouslySetInnerHTML={{ __html: content2[0] }} />
              </div>
              <div className="col-md-6 bgAbout p-5 col-sm-12 text-block ">
                <span dangerouslySetInnerHTML={{ __html: content2[1] }} />
              </div>
            </BackgroundImage>
          </Fade>

          <div className="row d-flex cards justify-content-center">
            <div className="col-md-12  text-center p-5 col-sm-12 text-block ">
              <h1 className="sentence ">{aboutfooternote}</h1>
            </div>
          </div>
          <div className="row pb-5 d-flex cards justify-content-center">
            <div className="col-md-12  text-center pr-0 col-sm-12 text-block ">
              <BookAppointment title={ctalabel} type={" primary-bg"} />
            </div>
          </div>
        </div>

        <Fade>
          <div className="row d-flex justify-content-center no-gutters">
            <div className="col-sm-12 pt-5 pb-5 pl-5 pr-5  align-self-center  text-block">
              <span dangerouslySetInnerHTML={{ __html: aboutcontent3 }} />
            </div>
          </div>
        </Fade>
        <BackgroundImage
          style={{
            backgroundPosition: "center bottom",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundOrigin: "content-box",
          }}
          className={
            "row bgImageFixed p-5 d-flex justify-content-center primary-bg"
          }
          fluid={aboutcontentimage1.localFile.childImageSharp.fluid}
        >
          <div className="col-md-12  bgAbout text-center pt-3 col-sm-12 text-block ">
            <span className="small destak">A citação da minha vida</span>
            <Fade>
              <h1
                className="sentence fg-text-color"
                dangerouslySetInnerHTML={{ __html: aboutsubtitle }}
              ></h1>
            </Fade>
          </div>
        </BackgroundImage>

        <div className="row p-5 d-flex justify-content-center cards">
          <h2 className="text-center pl-5 pr-5 ml-5 mr-5 primary-text-color">
            INFORMAÇÕES MAIS ABORRECIDAS SOBRE MIM
          </h2>
        </div>
        <div className="row pb-5 pl-5 pr-5 d-flex justify-content-center cards">
          {aboutpath.split("<hr />").map((v) => {
            return (
              <div class="card">
                <div
                  class="card-body"
                  dangerouslySetInnerHTML={{ __html: v }}
                ></div>
              </div>
            );
          })}
        </div>
      </section>

      <Footer />
    </Layout>
  );
};
export default IndexPage;
